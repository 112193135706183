function NavBar(props) {
    return (
        <nav className="flex items-center justify-between flex-wrap bg-white -500 p-6 shadow-lg sticky top-0 left-0 right-0">
            <div className="w-full block flex flex-row lg:flex lg:items-center lg:w-auto font-medium">
                <a href='#home' className="hover:animate-pulse font-quicksand block mt-0 lg:inline-block text-black text-opacity-50 hover:text-opacity-100 mr-4">home</a>
                <a href='#about' className="hover:animate-pulse font-mono block mt-0 lg:inline-block text-black text-opacity-50 hover:text-opacity-100 mr-4">.whoami</a>
                {/* <a href='#projects' className="hover:animate-pulse font-quicksand block mt-0 lg:inline-block text-black text-opacity-50 hover:text-opacity-100 mr-4">projects</a> */}
                {/* <a href="#list" className="hover:animate-pulse font-quicksand block mt-0 lg:inline-block text-black text-opacity-50 hover:text-opacity-100 mr-4">list</a> */}
            </div>
        </nav>
      );
}

export default NavBar;