function About(props) {
    return (
        <div className="text-white">
            <div className='flex items-center justify-center'>
                <div className='text-5xl font-mono font-bold'>.whoami</div>
            </div>
            <div className="flex flex-col items-center justify-center sm:justify-left lg:flex-row m-10">
                <img className="md:w-[300px] md:h-[300px] w-[200px] h-[200px] rounded-full lg:mr-10" src="/self.jpeg"/>
                <div className="flex flex-col m-10">
                    <p className="whitespace-wrap font-quicksand font-medium mb-2">
                        Hi 👋 I'm <span className="font-bold">Jenny</span>
                    </p>
                    <p className="whitespace-wrap font-quicksand font-medium mb-2">
                        Currently, I am building <a className="font-bold hover:underline" href="https://github.blog/changelog/2022-09-01-github-actions-larger-runners-are-now-in-public-beta/">GitHub Actions Larger Runners</a> 🏃‍♀️ 
                         at <a className="font-bold hover:underline" href="https://github.com">GitHub</a> 👩‍💻
                    </p>
                    <p className="whitespace-wrap font-quicksand font-medium mb-2">
                        In my free time, I like to knit/crochet 🧶 If you would like to see what I work on, please visit my <a className="font-bold hover:underline" href="https://www.ravelry.com/people/chaubacca">Ravelry</a>
                    </p>
                    <p className="whitespace-wrap font-quicksand font-medium mb-2">
                        I'm a casual gamer and a board game enjoyer 🎮 Currently, I play a lot of TFT and League of Legends
                    </p>
                    <p className="whitespace-wrap font-quicksand font-medium sm:mt-10">
                        Contact me at chaulyjenny[at]gmail.com
                    </p>
                </div>
            </div>
        </div>
      );
}

export default About;