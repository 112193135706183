import './output.css';
import NavBar from './components/navbar';
import Projects from './pages/projects';
import About from './pages/about';
import List from './pages/list';

function App() {
  return (
    <div className='relative'>
      <section id="home">
      </section>
      <NavBar/>
      <div>
        <div className='grid sm:flex sm:flex-row sm:items-center place-items-center justify-center text-white h-screen'>
          <div className='flex flex-col sm:pr-10 sm:item-center sm:justify-center'>
            <h1 className='text-7xl font-quicksand p-2'>Jenny Ly</h1>
            <h1 className='text-6xl inline-block sm:text-4xl font-quicksand p-2'>Lý Bảo Châu</h1>
          </div>
          <div className='flex flex-row'>
            <a href="https://github.com/lycb" target="_blank" rel="noreferrer">
              <img className="w-20 sm:w-full hover:animate-pulse" alt="GitHub logo" src="https://img.icons8.com/carbon-copy/80/FFFFFF/github.png"/>
            </a>
            <a href='https://www.linkedin.com/in/jenny-chau-ly/' target="_blank" rel="noreferrer">
              <img className="w-20 sm:w-full hover:animate-pulse" alt="LinkedIn logo" src="https://img.icons8.com/carbon-copy/80/FFFFFF/linkedin.png"/>
            </a>
          </div>
        </div>
      </div>
      <div>
        <section id="about">
          <div className='p-[50px]'></div>
        </section>
        <div className="min-h-screen">
          <About />
        </div>
        <section id="projects">
          <div className='p-[50px]'></div>
        </section>
        {/* <div className="min-h-screen">
          <Projects />
        </div> */}
        {/* <section id="list">
          <div className='p-[50px]'></div>
        </section>
        <div className="min-h-screen">
          <List />
        </div> */}
      </div>
    </div>
  );
}

export default App;
